import React from 'react';
import PropTypes from 'prop-types';
import Modal from 'react-bootstrap/Modal';
import { Button } from '../form';
import WinRedModal from './winred-modal.component';
import Typography from '../typography.component';

function ConfirmModal({
  children,
  classNameBody,
  title,
  show,
  onSubmit,
  handleClose,
  buttonText,
  buttonColor,
  showCancelButton,
  onCancel,
  cancelButtonColor,
  cancelButtonText,
  disableSubmit,
  subtitle,
  size,
}) {
  // dont allow both buttons to be the same color...
  if ((buttonColor === 'error' || buttonColor === 'cancel') && cancelButtonColor === 'cancel') {
    cancelButtonColor = 'outline-primary';
  }

  return (
    <WinRedModal show={show} onHide={handleClose} size={size}>
      {!title && !subtitle ? null : (
        <>
          <Modal.Header closeButton>
            <Modal.Title as="h5" className="winred-h5">
              {title}
            </Modal.Title>
          </Modal.Header>
          {subtitle ? <Typography className="pl-4">{subtitle}</Typography> : null}
        </>
      )}

      <Modal.Body className={classNameBody}>{children}</Modal.Body>
      <Modal.Footer className="d-flex align-items-between">
        {showCancelButton ? (
          <Button ariaLabel="cancel" variant={cancelButtonColor} onClick={onCancel || onSubmit}>
            {cancelButtonText}
          </Button>
        ) : null}
        <Button ariaLabel={buttonText} disabled={disableSubmit} variant={buttonColor} onClick={onSubmit}>
          {buttonText}
        </Button>
      </Modal.Footer>
    </WinRedModal>
  );
}

ConfirmModal.propTypes = {
  children: PropTypes.any.isRequired,
  handleClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
  subtitle: PropTypes.string.isRequired,
  showCancelButton: PropTypes.bool,
  onCancel: PropTypes.func,
  buttonText: PropTypes.string,
  buttonColor: PropTypes.string,
  cancelButtonColor: PropTypes.string,
  cancelButtonText: PropTypes.string,
  disableSubmit: PropTypes.bool,
  classNameBody: PropTypes.string,
  size: PropTypes.string,
};

ConfirmModal.defaultProps = {
  showCancelButton: false,
  title: '',
  subtitle: '',
  buttonText: 'Okay',
  buttonColor: 'primary',
  cancelButtonColor: 'cancel',
  cancelButtonText: 'Cancel',
  classNameBody: '',
  disableSubmit: false,
  size: "lg",
};

export default ConfirmModal;
