import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'modules/common';

function ButtonTrue({
  name,
  value,
  trueText,
  fullWidth,
  square,
  size,
  onChange,
  disabled,
  trueButtonColor,
  falseButtonColor,
  className,
}) {
  const onChangeTrue = onChange && !disabled ? () => onChange({ target: { name, value: true } }) : null;

  return (
    <Button
      variant={value ? trueButtonColor : falseButtonColor}
      alt={!value}
      square={square}
      fullWidth={fullWidth}
      size={size}
      disabled={disabled}
      onClick={onChangeTrue}
      className={className}
    >
      {trueText}
    </Button>
  );
}

ButtonTrue.propTypes = {
  name: PropTypes.string.isRequired,
  trueText: PropTypes.string,
  variant: PropTypes.string,
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  text: PropTypes.string,
  fullWidth: PropTypes.bool,
  square: PropTypes.bool,
  size: PropTypes.string,
  trueButtonColor: PropTypes.string,
  falseButtonColor: PropTypes.string,
  className: PropTypes.string,
};

ButtonTrue.defaultProps = {
  fullWidth: true,
  square: true,
  size: 'lg',
  value: true,
  disabled: false,
  trueText: 'Yes',
  variant: '',
  className: '',
  trueButtonColor: 'primary',
  falseButtonColor: 'default',
};

export default ButtonTrue;
