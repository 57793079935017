import honeybadger from 'honeybadger-js';
import {
  addDays,
  addWeeks,
  endOfDay,
  format,
  fromUnixTime,
  getUnixTime,
  isValid,
  parseISO,
  subQuarters,
  lastDayOfQuarter,
  startOfQuarter,
  subWeeks,
  subDays,
  startOfMonth,
  subMonths,
  lastDayOfMonth,
  startOfYear,
  differenceInSeconds,
  startOfDay,
  differenceInCalendarDays,
} from 'date-fns';

import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import isSameYearFNS from 'date-fns/isSameYear';
import formatWithTimezone from 'date-fns-tz/format';
import { store } from 'configureStore';
import enUS from 'date-fns/locale/en-US';

const DATE_FORMATTER = 'M/d/yyyy';
export const DATE_TIME_FORMATTER = 'M/d/yyyy hh:mm aa zzz';
export const MOMENT_FORMAT_IN_FNS = "yyyy-MM-dd'T'HH':'mm':'ssXXX";
export const UI_DATE_FORMAT = 'MMMM d, yyyy';
export const DATE_TIME_SENTENCE = "MMM dd, yyyy' at 'pp zzz";
export const UI_DATE_TIME_FORMAT = "MMMM d, yyyy' at 'pp zzz";

export const MONTH_DATE_FORMAT = 'MMMM d';

/* When parsing a date using the Date object, Date will convert the time to the
local timezone. This reverts that change so the date and time stays the same. */
const undoDateTimezoneConversion = date => {
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return zonedTimeToUtc(new Date(date), tz);
};

export const formatDate = (date, dateFormat = DATE_FORMATTER) => format(undoDateTimezoneConversion(date), dateFormat);

export const formatDateUTC = date => {
  const newDate = new Date(date);
  return `${newDate.getUTCMonth() + 1}/${newDate.getUTCDate()}/${newDate.getUTCFullYear()}`;
};

export const formatDateTime = (dateTime, dateFormat = DATE_TIME_FORMATTER) =>
  formatWithTimezone(undoDateTimezoneConversion(dateTime), dateFormat, {
    timeZone: _getCandidateTimezone(),
  });

export const formatDateTimeWithOrgTZ = dateTime => {
  return new Date(dateTime)
    .toLocaleString('en-US', { timeZone: _getCandidateTimezone(), timeZoneName: 'short' })
    .replace(',', '');
};

export const getDateOnly = dateTimeFormatted => {
  let formattedDate = dateTimeFormatted;
  // If this is a string, parse it.
  if (typeof dateTimeFormatted === 'string') {
    formattedDate = new Date(dateTimeFormatted);
  } else if (!(dateTimeFormatted instanceof Date)) {
    return '';
  }

  return toLocalISOString(formattedDate).split('T')[0];
};

export const getTomorrow = () => addDays(getCandidateDate(), 1);
export const getYesterday = () => subDays(getCandidateDate(), 1);

export const getTwelveWeeksFromNow = () => addWeeks(getCandidateDate(), 12);

export const getTomorrowFormatted = dateFormat => format(getTomorrow(), dateFormat || MOMENT_FORMAT_IN_FNS);
export const getYesterdayFormatted = () => {
  return format(getYesterday(), MOMENT_FORMAT_IN_FNS);
};

export const isSameYear = (date1, date2) => {
  if (!isValid(new Date(date1)) || !isValid(new Date(date2))) return false;
  return isSameYearFNS(date1, date2);
};

export const getTwoYearCycle = () => {
  const currentYear = new Date().getFullYear();
  const isCurrentCycle = currentYear % 2 === 0;

  return new Date(`1/1/${isCurrentCycle ? currentYear + 1 : currentYear + 2}`);
};

// Is date1 before date2?
export const isBeforeDay = (date1, date2) => differenceInCalendarDays(date1, date2) < 0;
export const isAfterDay = (date1, date2) => differenceInCalendarDays(date1, date2) > 0;

/* [ch21153] Date objects are mutable in javascript, so change the time to 5pm UTC.
This prevents changing the candidate timezone from changing the assigned
dates. Sometimes the timezone will be overwritten (such as when using
formatDateTime), so allow for setting the local time instead of UTC since the
timezone conversion is handled elsewhere. */
export const adjustTimeForConsistency = (date, setInLocalTime = false) => {
  if (setInLocalTime) {
    date.setHours(17, 0, 0, 0);
  } else {
    date.setUTCHours(17, 0, 0, 0);
  }
  return date;
};

// Get the dates based on the candidate timezone, not the users local timezone.
export const getMixMaxClosureDates = () => {
  const tomorrow = getTomorrow();
  const thirtyDaysFromTomorrow = addDays(getCandidateDate(), 30);
  /* Dates are mutable in javascript, so change the dates so the time is set to
  5pm UTC. This prevents changing the candidate timezone from changing the
  assigned dates. */
  return {
    minDate: getUnixTime(adjustTimeForConsistency(tomorrow)) * 1000,
    maxDate: getUnixTime(adjustTimeForConsistency(thirtyDaysFromTomorrow)) * 1000,
  };
};

export const getFormattedDate = (date, formatType = 'MM/dd/yyyy') => {
  if (!date) return '';
  if (isValid(date)) return format(date, formatType);

  const parsed = parseISO(date);
  if (!isValid(parsed)) return '';
  return format(parsed, formatType);
};
export const getFormattedMonthDay = date => (date ? formatDate(date, 'MM/dd') : '');

export const convertToCandidateDate = date => {
  try {
    const candidateTimezone = _getCandidateTimezone();
    const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    // Get the date in UTC then convert to the candidate timezone.
    const stillUtc = zonedTimeToUtc(date, localTimezone);
    return utcToZonedTime(stillUtc, candidateTimezone);
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const convertEpoch = (epoch, formatter = MOMENT_FORMAT_IN_FNS) => {
  const dateTime = convertEpochToDate(epoch);
  if (!dateTime) return dateTime;

  return formatWithTimezone(dateTime, formatter, {
    timeZone: _getCandidateTimezone(),
    locale: enUS,
  });
};
export const convertEpochToDate = epoch => {
  if (!epoch) return '';

  // If the epoch is a string, convert it to an integer before passing it
  if (typeof epoch === 'string' && epoch.match(/^[0-9]+$/)) epoch = parseInt(epoch, 10);

  // Don't return anything if invalid date
  if (!isValid(epoch)) return '';

  // Get the candidate and local timezones
  const candidateTimezone = _getCandidateTimezone();
  const localTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Get the date in UTC then convert to the candidate timezone.
  try {
    const stillUtc = zonedTimeToUtc(fromUnixTime(epoch), localTimezone);
    return utcToZonedTime(stillUtc, candidateTimezone);
  } catch (e) {
    console.error(e);
    return '';
  }
};

export const convertEpochDate = (epoch, format) => convertEpoch(epoch, format || DATE_FORMATTER);
export const convertEpochTime = epoch => convertEpoch(epoch, DATE_TIME_FORMATTER);

export const formatDateOfBirth = dob => {
  const dobDate = new Date(dob);

  if (!isValid(dobDate)) return null;
  return format(dobDate, 'MM/dd/yyyy');
};

/**
 * here we are getting only the date and validating it
 * we discard the time as we only need the date to remain the same for date picker
 * @param {string} recurringEndAt
 */
export const extractDate = recurringEndAt => {
  // if in date already then convert to string first
  if (recurringEndAt.toISOString) {
    recurringEndAt = toLocalISOString(recurringEndAt);
  }

  // get the date only
  const [dateOnly] = recurringEndAt.split(/ |T/);

  // validate that we have a valid date only
  if (!/^\d{4}-\d{2}-\d{2}$/.test(dateOnly)) {
    console.error(`Error parsing date`, { recurringEndAt, dateOnly });
    honeybadger.notify(`Error parsing date recurringEndAt:${recurringEndAt}, dateOnly:${dateOnly}`);
    return { isDateValid: false };
  }

  // get the year/month/day from the date only
  const [year, month, day] = dateOnly.split(/-|\//);

  // validate that we have a valid year/month/day
  if (year === undefined || month === undefined || day === undefined) {
    console.error(`Error parsing dateOnly`, { recurringEndAt, dateOnly, year, month, day });
    honeybadger.notify(
      `Error parsing dateOnly recurringEndAt:${recurringEndAt}, dateOnly:${dateOnly}, year:${year}, month:${month}, day:${day}`
    );
    return { isDateValid: false };
  }

  // create a new date object with local time zone but always the same date (force it)
  const today = new Date(year, month - 1, day);

  // validate AGAIN
  const isDateValid = isValid(new Date(today));
  if (!isDateValid) {
    console.error('Error invalid date', { recurringEndAt, today, dateOnly });
    honeybadger.notify(`Error invalid date recurringEndAt:${recurringEndAt}, dateOnly:${dateOnly}, today:${today}`);
  }

  // return what we got
  return { year, month, day, today, isDateValid };
};

export const getCandidateDate = date => {
  const _date = date ? new Date(date) : new Date();
  const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const candiateDate = utcToZonedTime(_date, tz);

  const candidateTimezone = _getCandidateTimezone();

  const candidateDate = utcToZonedTime(candiateDate, candidateTimezone);
  return candidateDate;
};

/**
 * toISOString() converts to UTC but we want to keep the set timezone
 * so when "converted" to UTC its actually the set timezone we want
 * we subtract offset from time -> date.getTimezoneOffset() * 60000 // number of milliseconds of tz offset from UTC
 */
const toLocalISOString = date => {
  const isoDateTime = new Date(date.getTime() - date.getTimezoneOffset() * 60000).toISOString();
  return isoDateTime;
};

// dashboard functions
const endOfTodayUtc = () => endOfDay(getCandidateDate());
const startOfPreviousMonth = () => startOfMonth(subMonths(getCandidateDate(), 1));
const endOfPreviousMonth = () => lastDayOfMonth(subMonths(getCandidateDate(), 1));
const startOfPastWeek = () => subWeeks(getCandidateDate(), 1);

export const getPastWeek = () => {
  return {
    dateRangeStart: getDateOnly(startOfPastWeek()),
    dateRangeEnd: getDateOnly(endOfTodayUtc()),
  };
};

export const getMonthToDate = () => {
  return {
    dateRangeStart: getDateOnly(startOfMonth(getCandidateDate())),
    dateRangeEnd: getDateOnly(endOfTodayUtc()),
  };
};

export const getPreviousMonth = () => {
  return {
    dateRangeStart: getDateOnly(startOfPreviousMonth()),
    dateRangeEnd: getDateOnly(endOfPreviousMonth()),
  };
};

export const getPreviousQuarter = () => {
  return {
    dateRangeStart: getDateOnly(startOfQuarter(subQuarters(getCandidateDate(), 1))),
    dateRangeEnd: getDateOnly(lastDayOfQuarter(subQuarters(getCandidateDate(), 1))),
  };
};

export const getYearToDate = () => {
  return {
    dateRangeStart: getDateOnly(startOfYear(getCandidateDate())),
    dateRangeEnd: endOfTodayUtc(),
  };
};

export const getAllTime = () => {
  return {
    dateRangeStart: null,
    dateRangeEnd: endOfTodayUtc(),
  };
};

export const getSecondsBeforeMidnight = () => {
  const midnight = startOfDay(addDays(getCandidateDate(), 1));
  return getSecondsBeforeDate(midnight);
};

export const getSecondsBeforeDate = date => {
  const rightNow = getCandidateDate();
  const selectedDate = getCandidateDate(date);
  return Math.abs(differenceInSeconds(rightNow, selectedDate).toFixed(0)).toString();
};

const getDayFromToday = numberOfDays => addDays(getCandidateDate(), numberOfDays);

export const getWidgetCountdownMinMaxDates = (days = 0) => {
  const minDate = getTomorrow().valueOf();
  const maxDate = getDayFromToday(days).valueOf();
  return { minDate, maxDate };
};

export const getRefreshDateTime = dateTime => {
  if (!isValid(dateTime)) {
    dateTime = new Date(dateTime);
    if (!isValid(dateTime)) return '';
  }
  return formatWithTimezone(dateTime, "MMM dd, yyyy' at 'pp zzz", { timeZone: _getCandidateTimezone(), locale: enUS });
};

const _getCandidateTimezone = () => {
  const state = store.getState();
  return state.login.profile?.candidate?.uiTimezone || state.login.profile?.candidate?.timezone || 'America/New_York';
};
