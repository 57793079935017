import React from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';
import classnames from 'classnames';

import { ValidatorComponent } from 'core/validation';
import { Typography, FloatGroup, Input, SubText } from 'modules/common';
import { noop } from 'core/utilities';
import { formatBlackDefaultTextColor } from './tools';
class ContentEditor extends ValidatorComponent {
  constructor(props) {
    super(props);

    this.state = {
      invalidMessage: null,
      initVal: props.initialValue, // dont use prop directly cause it changes and causes cursor bug
    };
  }

  onChange = value => {
    const formattedValue = formatBlackDefaultTextColor(value);
    this.props.onChange({ target: { name: this.props.name, value: formattedValue } });
  };

  render() {
    const {
      initialValue,
      onChange,
      name,
      readonly,
      toolbar,
      initOverrides,
      maxLength,
      value,
      maxLengthErrorMessage,
      description,
      description2,
      disabled,
      ...rest
    } = this.props;

    const _toolbar =
      toolbar ||
      'fontsizeselect |  fontselect | bold | italic | underline | strikethrough | alignleft | aligncenter | alignright | forecolor | backcolor | bullist numlist | code | link | image | removeformat';

    const errorMessage = this.errorMessage() || this.state.invalidMessage;

    return (
      <FloatGroup className={errorMessage ? 'content-editor-error' : ''}>
        <Editor
          width="100%"
          readonly={readonly ? 1 : 0}
          plugins="image link lists code table textcolor colorpicker"
          toolbar={_toolbar}
          initialValue={this.state.initVal}
          auto_focus={false}
          init={{
            auto_focus: false,
            branding: false,
            block_formats: 'Paragraph=p Header 1=h1 Header 2=h2 Header 3=h3',
            menubar: false,
            // selector: 'textarea.allow-tiny-script',
            fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
            skin_url: `${process.env.PUBLIC_URL}/skins/lightgray`,
            elementpath: false,
            valid_elements:
              'p[*],h1[*],h2[*],h3[*],h4[*],h5[*],h6[*],hr[*],div[*],table[*],tbody[*],th[*],tr[*],td[*],blockquote[*],em[*],strong[*],ol[*],ul[*],li[*],span[*],a[*],img[*],b[*],center[*],thead[*],u[*],br[*],strike[*],sub[*],sup[*]',
            ...initOverrides,
          }}
          onEditorChange={this.onChange}
          onBlur={this.onBlur}
          disabled={disabled}
          {...rest}
        />
        {description ? (
          <SubText
            description={description}
            description2={description2}
            className={classnames({ 'mb-0': errorMessage })}
          />
        ) : null}
        {maxLength && !errorMessage ? (
          <Input
            hidden
            validateOnMount
            value={value || ''}
            onChange={noop}
            validators={[newValue => !newValue || newValue.length < maxLength]}
            errorMessages={[maxLengthErrorMessage || `Input is too long. Max ${maxLength} characters.`]}
          />
        ) : null}

        {errorMessage && (
          <Typography variant="error" className="mt-1 mb-0">
            {errorMessage}
          </Typography>
        )}
      </FloatGroup>
    );
  }
}

ContentEditor.propTypes = {
  initialValue: PropTypes.string,
  initOverrides: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  maxLengthErrorMessage: PropTypes.string,
};
ContentEditor.defaultProps = {
  initOverrides: {},
  maxLengthErrorMessage: '',
};
export default ContentEditor;
