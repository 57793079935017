import React from 'react';
import PropTypes from 'prop-types';
import { FormCard, ContentEditor } from 'modules/common';

function Disclaimer({ campaign, onChange }) {
  return (
    <FormCard
      title="Shipping Disclaimer"
      subtitle="Add a shipping disclaimer for your product that appears on donation pages and reciepts"
    >
      <ContentEditor
        initialValue={campaign.shipping_disclaimer}
        name="shipping_disclaimer"
        value={campaign.shipping_disclaimer || ''}
        onChange={onChange}
        maxLength={500}
      />
    </FormCard>
  );
}

Disclaimer.propTypes = {
  campaign: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default Disclaimer;
