import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Row, Col } from 'react-bootstrap';

import { Typography, ExternalLink } from 'modules/common';
import { InputErrorMessage, ButtonTrue, ButtonFalse } from 'modules/common/form';
import { ValidatorComponent } from 'core/validation';
import './index.scss';

const FlexBinaryChoiceRow = ({
  title,
  subTitle,
  subTitle2,
  value,
  className,
  children,
  flipButtons = false,
  classNameButtons = '',
  disabled = false,
  learnMoreLink,
  ...rest
}) => {
  value = value || false;

  return (
    <Row className="winred-binary-choice">
      <Col sm={6} md={12} lg={5} xl={7} className={subTitle ? 'pr-5' : 'pr-5 d-flex align-items-center'}>
        <Typography className="mb-0" variant={subTitle ? 'h5' : 'bodyMedium'}>
          {title}
        </Typography>      
        {subTitle ? <Typography className={subTitle2  ? 'mb-0' : ''}>{subTitle}{' '}{learnMoreLink ? <ExternalLink href={learnMoreLink}>Learn More.</ExternalLink> : null }</Typography>  : null}                
        {subTitle2 ? <Typography className={subTitle2 ? 'mb-0' : ''}>{subTitle2}</Typography> : null}             
      </Col>

      <Col sm={6} md={12} lg={7} xl={5} className="align-items-start d-flex justify-content-between">
        {children}

        {flipButtons && (
          <ButtonFalse disabled={disabled} value={value} className={classnames('mr-2', className)} {...rest} />
        )}

        <ButtonTrue
          disabled={disabled}
          value={value}
          className={classnames({ 'mr-2': !flipButtons }, className)}
          {...rest}
        />

        {!flipButtons && <ButtonFalse disabled={disabled} value={value} className={className} {...rest} />}
      </Col>
    </Row>
  );
};

FlexBinaryChoiceRow.propTypes = {
  disabled: PropTypes.bool,
  name: PropTypes.string.isRequired,
  title: PropTypes.any,
  subTitle: PropTypes.any,
  subTitle2: PropTypes.any,
  value: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  trueText: PropTypes.string,
  falseText: PropTypes.string,
  trueButtonColor: PropTypes.string,
  falseButtonColor: PropTypes.string,
  className: PropTypes.string,
};

FlexBinaryChoiceRow.defaultProps = {
  className: '',
  value: false,
  disabled: false,
};

export class FlexBinaryRowValidator extends ValidatorComponent {
  render() {
    const { errorMessages, validators, ...restProps } = this.props;
    return (
      <>
        <FlexBinaryChoiceRow {...restProps} />
        <InputErrorMessage errorMessage={this.errorMessage()} />
      </>
    );
  }
}

export default FlexBinaryChoiceRow;
