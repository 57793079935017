import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faDollarSign,
  faToggleOff,
  faToggleOn,
  faTshirt,
  faArrowAltCircleDown,
  faCreditCard,
  faClipboard,
} from '@fortawesome/pro-regular-svg-icons';

import {
  faAddressCard,
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faAnalytics,
  faAngleDown,
  faArrowLeft,
  faArrowToBottom,
  faArrowToRight,
  faArrowFromBottom,
  faBars,
  faBell,
  faBoxAlt,
  faBullseyeArrow,
  faBriefcase,
  faChevronUp,
  faChevronDown,
  faCreditCardFront,
  faCog,
  faCogs,
  faDoorOpen,
  faDonate,
  faEdit,
  faEmptySet,
  faEnvelope,
  faEquals,
  faEllipsisH,
  faExchange,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLink,
  faFilePdf,
  faFolder,
  faGreaterThanEqual,
  faHandHoldingBox,
  faInfoCircle,
  faLessThanEqual,
  faLifeRing,
  faLockAlt,
  faMinusCircle,
  faMobile,
  faMoneyBillWave,
  faNewspaper,
  faPencil,
  faPenNib,
  faPlusCircle,
  faLevelUp,
  faQrcode,
  faRepeat,
  faSearch,
  faShoppingCart,
  faSignOut,
  faSlidersH,
  faFilter,
  faSitemap,
  faSync,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUser,
  faUsers,
  faWindowClose,
  faFileExport,
  faCopy,
  faBan,
  faCircle,
  faCheckCircle,
  faPlus,
  faVideo,
  faFileInvoiceDollar as fileInvoiceDollarLight,
} from '@fortawesome/pro-light-svg-icons';

import {
  faArrowRight,
  faCheck,
  faCloudUpload,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faArchive,
  faUniversity,
  faSquareFull,
  faFileInvoiceDollar as fileInvoiceDollarSolid,
  faDonate as faDonateSolid,
  faCog as faCogSolid,
  faEye,
  faSpinner,
  faAsterisk,
} from '@fortawesome/pro-solid-svg-icons';

import {
  faCcVisa,
  faCcAmex,
  faCcMastercard,
  faCcDiscover,
  faFacebook,
  faTwitter,
} from '@fortawesome/free-brands-svg-icons';

import upsellFlow from 'assets/images/upsell-flow.svg';
import upsellFlowBlockActions from 'assets/images/upsell-flow-block-actions.svg';
import doubleDonationUpsell from 'assets/images/upsells/Double Donation.svg';
import recurringUpsell from 'assets/images/upsells/Recurring Donation.svg';
import panelUpsell from 'assets/images/upsells/Panel.svg';
import committeePanelUpsell from 'assets/images/upsells/Conduit Panel.svg';
import SlateUpsell from 'assets/images/upsells/Conduit.svg';
import volunteerUpsell from 'assets/images/upsells/Volunteer.svg';
import mobileUpsell from 'assets/images/upsells/Mobile Opt-In.svg';
import contentUpsell from 'assets/images/upsells/Content.svg';
import singleUpsell from 'assets/images/upsells/Single.svg';
import multipleUpsell from 'assets/images/upsells/Multiple.svg';
import matchProUpsell from 'assets/images/upsells/Matchpro.svg';
import membershipUpsell from 'assets/images/upsells/Membership.svg';
import merchUpsell from 'assets/images/upsells/Merch.svg';
import subscribableUpsell from 'assets/images/upsells/Subscribable.svg';
import productUpsell from 'assets/images/upsells/Product.svg';
import moneyPledgeUpsell from 'assets/images/upsells/Money Pledge.svg';
import accountPasswordUpsell from 'assets/images/upsells/Account (Password).svg';

import circleArrowDown from 'assets/images/pathways/circle-arrow-down.svg';
import pathwayIcon from 'assets/images/pathways/Pathway_Icon.svg';
import pathwayGreenIcon from 'assets/images/pathways/Pathway_Green_Icon.svg';
import upsellPathwayIcon from 'assets/images/pathways/upsell-pathway.svg';
import upsellPathwayGreenIcon from 'assets/images/pathways/upsell-pathway-green.svg';
import conditionIcon from 'assets/images/pathways/condition.svg';
import conditionGreenIcon from 'assets/images/pathways/condition-green.svg';
import stepIcon from 'assets/images/pathways/Step_Icon.svg';
import endStepIcon from 'assets/images/pathways/End_step_icon.svg';

import cancelledSubscription from 'assets/images/donors/cancelled_subscription.svg';
import cancelledMoneyPledge from 'assets/images/donors/cancelled_money_pledge.svg';
import disputeIcon from 'assets/images/donors/dispute_icon.svg';
import donationIcon from 'assets/images/donors/donation_icon.svg';
import moneyPledge from 'assets/images/donors/money_pledge.svg';
import refundIcon from 'assets/images/donors/refund_icon.svg';
import subscriptionIcon from 'assets/images/donors/subscription_icon.svg';
import virtualTerminalDonationIcon from 'assets/images/donors/virtual_terminal_donation_.svg';
import smsOptInIcon from 'assets/images/SmsOptinDisclaimer.svg';
import thermometerIcon from 'assets/images/thermometer_icon.svg';
import voidIcon from 'assets/images/donors/void.svg';
import openGraphIcon from 'assets/images/open-graph-icon.svg';
import exitIntentIcon from 'assets/images/exit-intent-icon.svg';
import donorCoversFeesIcon from 'assets/images/donor-covers-fees-icon.svg';
import mobileDesktop from 'assets/images/mobile-desktop.svg';

import invoiceIn from 'assets/images/transactions/invoice-in.svg';
import invoiceOut from 'assets/images/transactions/invoice-out.svg';

import moneyPledgeReport from 'assets/images/reports/money_pledge.svg';
import disputesReport from 'assets/images/reports/disputes.svg';
import refundsReport from 'assets/images/reports/refunds.svg';
import subscriptionsReport from 'assets/images/reports/subscriptions.svg';
import feeReport from 'assets/images/reports/fee.svg';

import revvAccessoryIcon from 'assets/images/products/revv_accessory.svg';
import revvBookIcon from 'assets/images/products/revv_book.svg';
import revvDrinkwareIcon from 'assets/images/products/revv_drinkware.svg';
import revvHatIcon from 'assets/images/products/revv_hat.svg';
import revvHolidayItemIcon from 'assets/images/products/revv_holiday_item.svg';
import revvMembershipCardIcon from 'assets/images/products/revv_membership_card.svg';
import revvTshirtIcon from 'assets/images/products/revv_tshirt.svg';
import revvSignIcon from 'assets/images/products/revv_sign.svg';
import revvStickerIcon from 'assets/images/products/revv_sticker.svg';
import revvOtherIcon from 'assets/images/products/revv_other.svg';

import revvOuterwareIcon from 'assets/images/products/revv_outerwear.svg';
import highDollar from 'assets/images/high-dollar.svg';

import abTestActive from 'assets/images/ab_active.svg';
import abTestInactive from 'assets/images/ab_inactive.svg';
import betaTag from 'assets/images/beta.svg';

import backgroundImage from 'assets/images/background-image.svg';

import greenCalendar from 'assets/images/disputes/Green_Calendar.svg';
import yellowCalendar from 'assets/images/disputes/Yellow_Warning_Calendar.svg';
import orangeCalendar from 'assets/images/disputes/Orange_standard_level_calendar.svg';
import redCalendar from 'assets/images/disputes/Red_calendar.svg';

import confetti from 'assets/images/confetti.svg';

export const CUSTOM_ICONS = {
  'upsell-double-donation': doubleDonationUpsell,
  'upsell-recurring-donation': recurringUpsell,
  'upsell-panel': panelUpsell,
  'upsell-committee-panel': committeePanelUpsell,
  'upsell-conduit': SlateUpsell,
  'upsell-money-pledge': moneyPledgeUpsell,
  'upsell-volunteer': volunteerUpsell,
  'mobile-upsell': mobileUpsell,
  'upsell-content': contentUpsell,
  'upsell-single': singleUpsell,
  'upsell-multiple': multipleUpsell,
  'upsell-create-account': accountPasswordUpsell,
  'upsell-product': productUpsell,
  'upsell-merchandise': merchUpsell,
  'upsell-matchpro': matchProUpsell,
  'upsell-membership': membershipUpsell,
  'upsell-moneypledge': moneyPledgeUpsell,
  'upsell-subscribable': subscribableUpsell,
  'upsell-flow': upsellFlow,
  'upsell-flow-block-actions': upsellFlowBlockActions,

  'end-step-icon': endStepIcon,
  'step-icon': stepIcon,
  'pathway-icon': pathwayIcon,
  'pathway-green-icon': pathwayGreenIcon,
  'upsell-pathway-icon': upsellPathwayIcon,
  'upsell-pathway-green-icon': upsellPathwayGreenIcon,
  'condition-icon': conditionIcon,
  'condition-green-icon': conditionGreenIcon,
  'circle-arrow-down': circleArrowDown,

  'cancelled-subscription': cancelledSubscription,
  'cancelled-money-pledge': cancelledMoneyPledge,
  'dispute-icon': disputeIcon,
  'donation-icon': donationIcon,
  'money-pledge': moneyPledge,
  'refund-icon': refundIcon,
  'donation-vt-icon': virtualTerminalDonationIcon,
  'subscription-icon': subscriptionIcon,
  'void-icon': voidIcon,
  'sms-opt-in-disclaimer-icon': smsOptInIcon,
  'thermometer-icon': thermometerIcon,
  'openGraph-icon': openGraphIcon,
  'exitIntent-icon': exitIntentIcon,
  'donorCoversFees-icon': donorCoversFeesIcon,
  'mobileDesktop-icon': mobileDesktop,

  'money-pledge-report': moneyPledgeReport,
  'disputes-report': disputesReport,
  'refunds-report': refundsReport,
  'subscriptions-report': subscriptionsReport,
  'fee-report': feeReport,

  'invoice-in': invoiceIn,
  'invoice-out': invoiceOut,

  'product-accessory': revvAccessoryIcon,
  'product-book': revvBookIcon,
  'product-drinkware': revvDrinkwareIcon,
  'product-hat': revvHatIcon,
  'product-holiday-item': revvHolidayItemIcon,
  'product-membership-card': revvMembershipCardIcon,
  'product-tshirt': revvTshirtIcon,
  'product-sign': revvSignIcon,
  'product-sticker': revvStickerIcon,
  'product-outerwear': revvOuterwareIcon,
  'product-other': revvOtherIcon,

  'high-dollar': highDollar,

  'green-calendar': greenCalendar,
  'yellow-calendar': yellowCalendar,
  'orange-calendar': orangeCalendar,
  'red-calendar': redCalendar,

  'ab-test-active': abTestActive,
  'ab-test-inactive': abTestInactive,
  'background-image': backgroundImage,
  confetti: confetti,
  beta: betaTag,
};

// fontawesome doesnt allow the same icon name (regular vs solid) so we change the solid name here to have both
const faCogSolidified = { ...faCogSolid, iconName: 'cog-solid' };
const faDonateSolidified = { ...faDonateSolid, iconName: 'donate-solid' };
const faFileInvoiceDollarSolidified = { ...fileInvoiceDollarSolid, iconName: 'file-invoice-dollar-solid' };

export const FA_ICONS = [
  faFileExport,
  faAddressCard,
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faAnalytics,
  faAngleDown,
  faArrowLeft,
  faArrowToBottom,
  faArrowToRight,
  faArrowRight,
  faArrowFromBottom,
  faBars,
  faBell,
  faBullseyeArrow,
  faBoxAlt,
  faCaretDown,
  faCaretRight,
  faCaretUp,
  faChevronUp,
  faChevronDown,
  faCcAmex,
  faCcDiscover,
  faCcMastercard,
  faCcVisa,
  faCreditCardFront,
  faCheck,
  faPlusCircle,
  faCloudUpload,
  faClipboard,
  faCog,
  faDoorOpen,
  faCogSolidified,
  faCogs,
  faCopy,
  faDollarSign,
  faDonate,
  faDonateSolidified,
  faEdit,
  faEllipsisH,
  faEmptySet,
  faEnvelope,
  faEquals,
  faExchange,
  faExclamationCircle,
  faExclamationTriangle,
  faExternalLink,
  faFacebook,
  faFilePdf,
  faFilter,
  faFolder,
  faGreaterThanEqual,
  faHandHoldingBox,
  faInfoCircle,
  faLessThanEqual,
  faLifeRing,
  faLockAlt,
  faArchive,
  faMinusCircle,
  faMobile,
  faMoneyBillWave,
  faNewspaper,
  faPencil,
  faPenNib,
  faPlusCircle,
  faRepeat,
  faSearch,
  faShoppingCart,
  faSignOut,
  faSlidersH,
  faSquareFull,
  faSync,
  faThumbtack,
  faTimes,
  faTimesCircle,
  faToggleOff,
  faToggleOn,
  faTrashAlt,
  faTshirt,
  faTwitter,
  faUniversity,
  faUser,
  faUsers,
  faWindowClose,
  faBriefcase,
  faSitemap,
  faLevelUp,
  faQrcode,
  faFileInvoiceDollarSolidified,
  faBan,
  faCircle,
  faCheckCircle,
  faEye,
  faPlus,
  fileInvoiceDollarLight,
  faSpinner,
  faVideo,
  faArrowAltCircleDown,
  faAsterisk,
  faCreditCard,
].reduce((icons, icon) => {
  icons[icon.iconName] = icon;
  return icons;
}, {});

export const buildIconLibrary = () => {
  library.add(...Object.values(FA_ICONS));
};
