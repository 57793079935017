import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Card, ContentEditor,ExternalLink } from 'modules/common';
import { CustomUpsell } from '../';

function UpsellAction({ editedUpsell, isRequired = true, onUpdateEditedUpsell }) {
  const shouldRequireCallToAction = useCallback(() => {
    if (editedUpsell.enhanced === false && editedUpsell.paragraph === '') return false;
    if (editedUpsell.type === CustomUpsell.type && editedUpsell.paragraph === '') return false;
    return true;
  }, [editedUpsell.enhanced, editedUpsell.paragraph, editedUpsell.type]);

  return (
    <div className="mb-4">
      <Card.Title subtitle={
        <> 
          Write a compelling call to action to encourage donors to accept the upsell.&nbsp; 
              <ExternalLink href="https://support.winred.com/en/articles/6960442-customize-your-page-with-url-parameters">
                Learn More.
              </ExternalLink>
        </>}>
        Call to Action
      </Card.Title>
      
      

      <ContentEditor
        name="paragraph"
        initialValue={editedUpsell.paragraph}
        value={editedUpsell.paragraph}
        onChange={onUpdateEditedUpsell}
        disabled={editedUpsell._isDisabled}
        validators={isRequired ? [shouldRequireCallToAction] : []}
        errorMessages={['Call to Action is required.']}
      />
    </div>
  );
}

UpsellAction.propTypes = {
  editedUpsell: PropTypes.object.isRequired,
  isRequired: PropTypes.bool,

  onUpdateEditedUpsell: PropTypes.func.isRequired,
};

export default UpsellAction;
