import React, { useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { Editor } from '@tinymce/tinymce-react';

import useValidation from './useValidation';
import InputErrorMessage from 'modules/common/form/input-error-message.component';
import SubText from 'modules/common/sub-text.component';
import './inputs.scss';
import { formatBlackDefaultTextColor } from '../tools';

const VALID_ELEMENTS =
  'p[*],h1[*],h2[*],h3[*],h4[*],h5[*],h6[*],hr[*],div[*],table[*],tbody[*],th[*],tr[*],td[*],blockquote[*],em[*],strong[*],ol[*],ul[*],li[*],span[*],a[*],img[*],b[*],center[*],thead[*],u[*],br[*],strike[*],sub[*],sup[*]';

function WinRedTextEditor({
  placeholder = '',
  className = '',
  onChange,
  name = '',
  value,
  maxLength = 255,
  onFocus,
  onBlur,
  children,
  validators,
  errorIcon,
  validateOnMount,
  description,
  subDescription,
  readonly,
  toolbar,
  initOverrides,
  initialValue,
  ...rest
}) {
  const [initVal] = useState(initialValue);
  const ref = useRef();

  const [validationBlur, validationError] = useValidation({
    value,
    validators,
    validateOnMount,
    onBlur,
    ref: ref?.current?.elementRef?.current,
  });

  const _onChange = useCallback(
    value => {
      const formattedValue = formatBlackDefaultTextColor(value);
      onChange({ target: { name, value: formattedValue } });
    },
    [onChange, name]
  );

  const handleFocusChange = useCallback(
    event => {
      if (onFocus) onFocus(event);
    },
    [onFocus]
  );

  const handleBlurChange = useCallback(
    event => {
      validationBlur(event);
    },
    [validationBlur]
  );

  const _toolbar =
    toolbar ||
    'fontsizeselect | fontselect | bold | italic | underline | strikethrough | alignleft | aligncenter | alignright | forecolor | backcolor | bullist numlist | code | link | image | removeformat';

  return (
    <div className={'winred-input2 ' + (validationError ? 'winred-text-editor-error' : '')}>
      <Editor
        width="100%"
        readonly={readonly ? 1 : 0}
        plugins="image link lists code table textcolor"
        toolbar={_toolbar}
        initialValue={initVal}
        auto_focus={false}
        ref={ref}
        init={{
          auto_focus: false,
          branding: false,
          block_formats: 'Paragraph=p Header 1=h1 Header 2=h2 Header 3=h3',
          menubar: false,
          // selector: 'textarea.allow-tiny-script',
          fontsize_formats: '8pt 10pt 12pt 14pt 18pt 24pt 36pt',
          skin_url: `${process.env.PUBLIC_URL}/skins/lightgray`,
          elementpath: false,
          valid_elements: VALID_ELEMENTS,

          ...initOverrides,
        }}
        onBlur={handleBlurChange}
        onFocus={handleFocusChange}
        onEditorChange={_onChange}
        {...rest}
      />

      <InputErrorMessage errorMessage={validationError} errorIcon={errorIcon} />
      {description ? <SubText description={description} description2={subDescription} /> : null}
      {children}
    </div>
  );
}

WinRedTextEditor.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  onChange: PropTypes.func.isRequired,

  placeholder: PropTypes.string,
  className: PropTypes.string,
  maxLength: PropTypes.number,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  children: PropTypes.node,

  validators: PropTypes.array,
  errorIcon: PropTypes.string,
  validateOnMount: PropTypes.bool,
  description: PropTypes.string,
  subDescription: PropTypes.string,
  readonly: PropTypes.bool,
  toolbar: PropTypes.string,
  initOverrides: PropTypes.object,
  initialValue: PropTypes.string,
};

export default WinRedTextEditor;
