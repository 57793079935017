import gql from 'graphql-tag';

const SETTINGS_FRAGMENT = gql`
  fragment Settings on Candidate {
    revv_uid
    created_at

    name
    first_name
    last_name
    fec_registration_id
    timezone
    contact_email
    processor

    default_page_uid
    default_money_bomb_revv_uid
    default_upsell_process_type
    default_upsell_group_revv_uid
    default_upsell_pathway_revv_uid
    confirmation_dialog_contribution_cents
    slug
    default_source_code
    statement_descriptor
    maximum_contribution_cents
    domain
    custom_subdomains_allowed
    organization_subdomains {
      id
      subdomain
      status
    }

    display_global_disclaimer
    global_disclaimer
    disclaimer
    long_disclaimer
    recurring_callout

    payout_frequency
    next_payout_date

    opt_in_to_directory
    storefront_opt_in_to_directory
    district
    office
    state
    election_year

    enable_donor_cover_fees

    users {
      revv_uid
      first_name
      last_name
      email
      role_type
      install_two_factor
      two_factor_required
    }

    avatar {
      id
      image {
        id
        thumbnailUrl
      }
    }

    account {
      business_name
      business_website
      business_phone
      tax_id
      address
      address2
      city
      state
      zip
      status
    }

    owners {
      revv_uid
      email
      first_name
      last_name
      title
      phone
      dob_day
      dob_month
      dob_year
      ssn_last_4
      full_ssn_token_provided
      address
      address2
      city
      state
      zip
      stripe_identity_document_front_id
      stripe_identity_document_back_id
      account_opener
      owner
      status
    }

    bank_accounts {
      revv_uid
      account_holder_name
      routing_number
      last4
      account_holder_type
      bank_name
      status
      currency
      country
      default_for_currency
    }

    account_closure_setting {
      remove_from_conduiting
      remove_from_conduiting_on
      archive_pages_on
      archive_pages
      cancel_recurring_and_pledges_on
      cancel_recurring_and_pledges
      confirmation_paragraph
      confirmation_custom_link
      confirmation_custom_link_copy
    }

    allow_donations_to_process

    can_accept_business_donations
    can_accept_pac_donations
    hide_verification_message
    
    contribution_setting {
      enable_business_donations
      business_donations_by_default
      business_donations_maximum_amount_cents
      enable_business_donations_type_of_business
      enable_business_donations_tax_id
      enable_pac_donations
      pac_donations_maximum_amount_cents
      enable_pac_donations_fec_id

      type_of_business_callout
      enable_business_donations_type_of_business_dropdown
      additional_business_disclaimer
      additional_pac_disclaimer

      business_donations_acknowledged
      pac_donations_acknowledged
      pac_donation_terms_acknowledged_at
      business_donation_terms_acknowledged_at
      pac_donation_terms_acknowledged_by
      business_donation_terms_acknowledged_by
    }
    default_setting {
      smsOptinCallout
      enableSmsOptin
      mobileDisclaimerText
    }
    marketing_setting {
      ogTitle
      ogVideo
      ogDescription
      ogAssignedImage {
        id
        image {
          id
          thumbnailUrl
        }
      }
      twitterTitle
      twitterDescription
      twitterAssignedImage {
        id
        image {
          id
          thumbnailUrl
        }
      }
    }
  }
`;

export const GET_SETTINGS_QUERY = gql`
  query getSettings($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        ...Settings
      }
    }
  }
  ${SETTINGS_FRAGMENT}
`;

export const GET_BANK_ACCOUNT_ACTIVITY = gql`
  query getSettingsBankLogs($organizationRevvUid: String) {
    viewer {
      candidate(revvUid: $organizationRevvUid) {
        bank_accounts_activity {
          results {
            email
            type
            last4
            date
          }
        }
      }
    }
  }
`;

export const SETTINGS_MUTATION = gql`
  mutation saveSettings(
    $candidate: ConduitCandidateInput!
    $account: CandidateAccountInput!
    $organizationRevvUid: String
  ) {
    updateCandidate(candidate: $candidate, organizationRevvUid: $organizationRevvUid) {
      errors
      candidate {
        ...Settings
      }
    }
    updateAccount(account: $account, organizationRevvUid: $organizationRevvUid) {
      errors
    }
  }
  ${SETTINGS_FRAGMENT}
`;

export const SETTINGS_NO_PORTAL_MUTATION = gql`
  mutation saveNoPortalSettings($candidate: ConduitCandidateInput!, $organizationRevvUid: String) {
    updateCandidate(candidate: $candidate, organizationRevvUid: $organizationRevvUid) {
      errors
      candidate {
        ...Settings
      }
    }
  }
  ${SETTINGS_FRAGMENT}
`;
