import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'modules/common';

function ButtonFalse({
  name,
  value,
  falseText,
  fullWidth,
  square,
  size,
  onChange,
  disabled,
  className,
  trueButtonColor,
  falseButtonColor,
}) {
  const onChangeFalse = onChange && !disabled ? () => onChange({ target: { name, value: false } }) : null;

  return (
    <Button
      variant={!value ? trueButtonColor : falseButtonColor}
      alt={value}
      className={className}
      square={square}
      fullWidth={fullWidth}
      size={size}
      disabled={disabled}
      onClick={onChangeFalse}
    >
      {falseText}
    </Button>
  );
}

ButtonFalse.propTypes = {
  name: PropTypes.string.isRequired,
  falseText: PropTypes.string,
  value: PropTypes.bool,
  onChange: PropTypes.func,
  text: PropTypes.string,
  fullWidth: PropTypes.bool,
  square: PropTypes.bool,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  trueButtonColor: PropTypes.string,
  falseButtonColor: PropTypes.string,
};

ButtonFalse.defaultProps = {
  fullWidth: true,
  square: true,
  size: 'lg',
  value: false,
  falseText: 'No',
  disabled: false,
  trueButtonColor: 'primary',
  falseButtonColor: 'default',
};

export default ButtonFalse;
