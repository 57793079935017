import React from 'react';
import PropTypes from 'prop-types';
import { FormCard, ContentEditor } from 'modules/common';
import 'modules/pages/pages.scss';

export default function CallToAction({ description, page, updatePageField, isTeamPages }) {
  const toolbar = isTeamPages
    ? 'fontsizeselect | bold | italic | underline | strikethrough | alignleft | aligncenter | alignright | forecolor | backcolor | bullist numlist | link | removeformat'
    : null;
  return (
    <FormCard title="Call to Action" subtitle={description}>
      <ContentEditor
        initialValue={page.callToAction}
        toolbar={toolbar}
        name="callToAction"
        value={page.callToAction}
        onChange={updatePageField}
        disabled={page._disabled}
      />
    </FormCard>
  );
}

CallToAction.propTypes = {
  description: PropTypes.string,
  page: PropTypes.object.isRequired,
  updatePageField: PropTypes.func.isRequired,
  isTeamPages: PropTypes.bool.isRequired,
};

CallToAction.defaultProps = {
  description: 'Write a strong call to action to encourage people to donate.',
};
